import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Rating from "../sidebar/rating";
import axios from "axios"; 
import { useTranslation } from "react-i18next";

const Instructor = () => {
    const apiURL = process.env.REACT_APP_BACKEND_URL;
    const [t, i18n] = useTranslation("global");
    const handleChangeLanguage = (lang) => {
      i18n.changeLanguage(lang);
    };
    const subTitle = `${t("instructor.instructor.1")}`;
    const title = `${t("instructor.instructor.2")}`;
    const [instructorList, setInstructorList] = useState([]);
    const [totalCourses, setTotalCourses] = useState(0);

    useEffect(() => {
        const fetchInstructors = async () => {
            try {
                const response = await axios.get(`${apiURL}/trainer/getTrainers`);
                setInstructorList(response.data);
            } catch (error) {
                console.error("Error fetching instructors:", error);
            }
        };

        fetchInstructors();
    }, []);

    useEffect(() => {
        let total = 0;
        instructorList.forEach((instructor) => {
            total += instructor.courses.length;
        });
        setTotalCourses(total);
    }, [instructorList]);

    const lastThreeInstructor = instructorList.slice(-4);

    return (
        <div className="instructor-section padding-tb section-bg">
            <div className="container">
                <br /><br />
                <div className="section-header text-center">
                    <span className="subtitle">{subTitle}</span>
                    <h2 className="title">{title}</h2>
                </div>
                <div className="section-wrapper">
                    <div className="row g-4 justify-content-center row-cols-1 row-cols-sm-2 row-cols-lg-3 row-cols-xl-4">
                        {lastThreeInstructor.map((val, i) => (
                            <div className="col" key={i}>
                                <div className="instructor-item d-flex flex-column h-100">
                                    <div className="instructor-inner flex-grow-1">
                                        <div className="instructor-thumb">
                                            <img 
                                                src={val.image} 
                                                alt={val.imgAlt} 
                                                style={{ height: '150px', width: '150px' }} 
                                            />
                                        </div>
                                        <div className="instructor-content flex-grow-1 d-flex flex-column justify-content-between">
                                            <Link to="/">
                                                <h4>{val.firstname} {val.lastname}</h4>
                                            </Link>
                                            <p>{val.formation}</p>
                                            <Rating />
                                        </div>
                                    </div>
                                    <div className="instructor-footer">
                                        <ul className="lab-ul d-flex flex-wrap justify-content-between align-items-center">
                                            <li>
                                                <i className="icofont-book-alt"></i> {val.courses.length} cours
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="text-center footer-btn">
                        <p>{t("instructor.instructor.3")} <Link to="/team">{t("instructor.instructor.4")}</Link></p>
                    </div>
                </div>
            </div>
            <style jsx>{`
                .instructor-item {
                    display: flex;
                    flex-direction: column;
                    height: 100%; /* Ensure the item takes full height */
                }

                .instructor-inner {
                    flex-grow: 1; /* Allows the inner content to grow */
                }

                .instructor-content {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between; /* Distribute space between elements */
                    flex-grow: 1; /* Allow content to fill available space */
                }

                .instructor-footer {
                    margin-top: auto; /* Push the footer to the bottom */
                }
            `}</style>
        </div>
    );
};

export default Instructor;